import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { container, bubbles, loadingPage, loadingComponent } from './loading.module.scss';

var classes = {
  loadingPage: loadingPage,
  loadingComponent: loadingComponent,
};

export default function Loading({ loadingKind }) {
  return (
    <div className={`${container} ${classes[loadingKind]}`}>
      <ReactLoading type={'bubbles'} className={bubbles} height={'125px'} width={'125px'} />
    </div>
  );
}

Loading.propTypes = {
  loadingKind: PropTypes.string,
};
